!function ($, Site) {

    var Prodo_GallerySlider = function () {

      // depends on /vendor/slick/slick.js

      var prevArrowHtml = '<button class="m-arrow-controls__control m-arrow-controls__control--prev slide-prev" data-role="none" aria-label="Previous slide" role="button"><div class="m-icon"><?xml version="1.0" encoding="utf-8"?><svg class="m-icon__element m-icon__element--icon u-fill-dark-green"><use xmlns:xlink="http://www.w3.org/1999/xlink"xlink:href="/_frontend/images/sprites/_svg-sprite.svg#icon-chevron-left"></use></svg></div><span class="u-hide-text">Previous slide</span></button></div>',
      nextArrowHtml = '<button class="m-arrow-controls__control m-arrow-controls__control--next slide-next" data-role="none" aria-label="Next slide" role="button"><div class="m-icon"><?xml version="1.0" encoding="utf-8"?><svg class="m-icon__element m-icon__element--icon u-fill-dark-green"><use xmlns:xlink="http://www.w3.org/1999/xlink"xlink:href="/_frontend/images/sprites/_svg-sprite.svg#icon-chevron-right"></use></svg></div><span class="u-hide-text">Next slide</span></button></div>';

      var ssg_slides_to_show = 3;

      // if (Foundation.MediaQuery.atLeast('large')) {
      //   ssg_slides_to_show = 5;
      // }

      $('.js-gallery__slides').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: prevArrowHtml,
        nextArrow: nextArrowHtml,
        fade: true,
        asNavFor: '.js-gallery__thumbs',
        appendArrows: '.m-gallery__controls'
      });
      $('.js-gallery__thumbs').slick({
        slidesToShow: ssg_slides_to_show,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.js-gallery__slides',
        centerMode: true,
        focusOnSelect: true
      });

	};

    Site.RegisterModule(Prodo_GallerySlider);

}(jQuery, Prodo_Site);
