!function ($, Site) {
    var Prodo_InternalNav = function () {

        var $internalNav = $('.js-internal-nav'),
        $internalNav_trigger = $('[data-internal-nav="trigger"]'),
        $internalNav_toggle = $('.js-internal-nav__toggle'),
        $internalNav_subnav = $('[data-internal-nav="sub-nav"]'),
        $internalNav_linkgroup = $('[data-internal-nav="sub-nav"]');


        if ($internalNav.length) {

            $internalNav_trigger.click(function () {
                $(this).parent($internalNav_linkgroup).toggleClass('active');
                $(this).parent().siblings($internalNav_subnav).slideToggle();
                $(this).parent().parent().toggleClass('active');
            });

            $internalNav_toggle.on('click', function(){

              $(this).toggleClass('');
              $internalNav.slideToggle();

            });

        }

    };

    Site.RegisterModule(Prodo_InternalNav);

}(jQuery, Prodo_Site);
